import {
  styled, rem
} from 'config/stitches.config'

import { StyledSectionContent } from '@/Section/Container/styles'

import Text from '@/UI/Text'

export const StyledNotFound = styled('main', { backgroundColor: '$white' })

export const StyledNotFoundContent = styled('section', {
  height: '100vh',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
})

export const StyledNotFoundContainer = styled(StyledSectionContent, { paddingY: 0 })

export const StyledNotFoundTitle = styled(Text, {
  paddingY: rem(12),
  paddingX: '$3',
  br: 60,
  fontSize: rem(14),
  lineHeight: rem(16),
  display: 'inline-block',
  backgroundColor: '$primary',
  color: '$white',
})
