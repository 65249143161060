import React from 'react'
import { useRouter } from 'next/router'
import Marquee from 'sections/Marquee'

import Cta from '@/UI/Cta/DefaultCta'

import {
  StyledNotFound,
  StyledNotFoundContent,
  StyledNotFoundContainer,
  StyledNotFoundTitle
} from './styles'

const NotFoundSection = ({
  content,
  title,
  ctaTitle
}) => {
  const { locale } = useRouter()

  return (
    <StyledNotFound>

      <StyledNotFoundContent>
        <StyledNotFoundContainer>
          {
            title &&
            <StyledNotFoundTitle size="p3-mid">
              {title}
            </StyledNotFoundTitle>
          }
        </StyledNotFoundContainer>
        {
          content &&
          <Marquee content={content} />
        }
        <StyledNotFoundContainer>
          {
            ctaTitle &&
            <Cta href="/" locale={locale} withIcon size="big" icon="arrowRight">{ctaTitle}</Cta>
          }
        </StyledNotFoundContainer>
      </StyledNotFoundContent>
    </StyledNotFound>
  )
}

export default NotFoundSection
